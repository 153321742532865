@if $password_strengthen {
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($bp--medium-up) {
        position: absolute;
        #{$ldirection}: 105%;
        bottom: -25%;
        width: 240px;
        &-checkout {
          bottom: calc(60% - 50px);
          #{$ldirection}: 98%;
        }
        &-book_appoint {
          bottom: auto;
          -webkit-transform: translateY(-92%);
          -moz-transform: translateY(-92%);
          -o-transform: translateY(-92%);
          transform: translateY(-92%);
          position: absolute;
          #{$ldirection}: 64%;
        }
      }
      width: 100%;
      background-color: $color--white;
      z-index: 99;
      margin-top: 5px;
      &:before {
        @include breakpoint($bp--medium-up) {
          content: '';
          position: absolute;
          top: 43%;
          #{$rdirection}: 100%;
          border-width: 5px;
          border-style: solid;
          border-color: transparent $color--gray--dark transparent transparent;
        }
      }
    }
    &__rules {
      @include breakpoint($bp--medium-up) {
        padding: 10px;
        font-size: 1em;
        width: 104%;
        list-style: none;
      }
      font-size: 0.8em;
      width: 100%;
    }
    li {
      width: 100%;
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color--gray--dark;
      }
    }
    .form-submit {
      &:disabled,
      &.disabled {
        border: 1px solid $color--gray;
      }
    }
  }
  .password-reset-page {
    .password-reset {
      position: relative;
      .password-field__info-reset {
        @include breakpoint($bp--xlarge-up) {
          top: -39px;
          bottom: auto;
        }
        @include breakpoint($bp--medium-up) {
          bottom: auto;
        }
      }
    }
  }
  .appt-book-section {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          #{$ldirection}: 82%;
          bottom: auto;
          position: absolute;
          transform: translateY(-92%);
          -webkit-transform: translateY(-92%);
          -moz-transform: translateY(-92%);
          -o-transform: translateY(-92%);
        }
      }
    }
    .booking-step {
      .password-field {
        &__info {
          @include breakpoint($bp--medium-up) {
            #{$ldirection}: 82%;
            bottom: auto;
            position: absolute;
            transform: translateY(-92%);
            -webkit-transform: translateY(-92%);
            -moz-transform: translateY(-92%);
            -o-transform: translateY(-92%);
          }
        }
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        width: 55%;
      }
    }
    &__action {
      input[type='submit'] {
        &:disabled,
        &.disabled {
          color: $color--black;
          background-color: $color--white;
          cursor: not-allowed;
        }
      }
    }
  }
}

#change_password {
  .password-field__rules {
    column-count: 2;
  }
}
