.samples-page {
  .samples-panel__continue-checkout--button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0 15px 15px 15px;
    background: white;
    z-index: 100;
  }
  #samples-panel {
    padding-bottom: 60px;
    .opc__footer {
      padding: 2em 0 3em 0;
    }
  }
}

.checkout {
  &.viewcart {
    .checkout-header {
      .continue-shopping {
        display: none;
      }
      &__title {
        font-size: 24px;
      }
      &__samples {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    #top-viewcart-buttons {
      display: none;
    }
    .cart-item {
      margin: 1em 0 2em 0;
      .product {
        &__thumb {
          width: 35%;
        }
        &__desc {
          position: relative;
          width: 65%;
          .cart-item__product-name {
            padding-right: 18px;
            margin-bottom: 0.1em;
          }
          .cart-item__product-price {
            font-family: $ano-bold_regular-font;
            font-weight: normal;
            text-transform: uppercase;
            font-size: 18px;
            font-size: 1.5rem;
            line-height: 1.11111;
            margin-bottom: 0.25em;
            margin-top: 0.25em;
            line-height: 13px;
            margin-bottom: 0.7em;
          }
        }
      }
      .remove_link {
        position: absolute;
        top: 12px;
        right: 0;
        text-decoration: none;
        border: 0;
        font-size: 16px;
      }
      .cart-item__total {
        display: none;
      }
      &__select__container {
        .cart-item__qty {
          margin-left: 0;
          float: none;
          select.selectbox {
            margin-bottom: 0;
          }
        }
      }
      .cart-item__qty {
        select.selectbox {
          height: 60px;
        }
      }
    }
    .order-summary-panel {
      .ship-method {
        width: 100%;
        margin-bottom: 0px;
        select[name='SHIP_METHOD'] {
          border: 1px solid black;
        }
      }
      &__title {
        font-size: 19px;
        border-bottom: 1px solid #c7c7c7;
        padding-bottom: 10px;
        margin-bottom: 10px;
        display: block;
      }
      .order-summary__shipping-content {
        height: 50px;
      }
      .order-summary__content {
        .label,
        .value {
          font-size: 18px;
          font-family: $headline-font-family;
          margin-bottom: 1em;
        }
      }
      .order-summary__total-label,
      .order-summary__total-value {
        font-size: 19px;
        background: #f0f0f0;
        padding: 18.5px 10px;
        margin-bottom: 0;
        width: 60%;
      }
      .order-summary__total-value {
        width: 44%;
        padding-left: 0;
      }
    }
    #sticky-checkout-button {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      padding: 0 15px 15px 15px;
      background: white;
      .continue-checkout {
        width: 100%;
      }
    }
    .links-panel,
    .need-help-panel {
      display: none;
    }
    .checkout__sidebar {
      #offer_code {
        margin-top: 15px;
      }
    }
    .cart-item__product-price {
      &--non-sale {
        text-decoration: line-through;
      }
      &--sale {
        color: red;
      }
    }
  }
  &.signin-wrapper {
    .signin-panel {
      input[type='submit'] {
        width: 100%;
      }
    }
    .checkout-header__title {
      font-size: 24px;
    }
  }
  .shipping-panel {
    padding: 0;
    .delivery-info-content {
      .shipform_fields {
        label {
          display: inline-block;
        }
      }
    }
    #payment-method {
      #payment-container {
        label.picker-label {
          width: 84%;
        }
      }
    }
    #continue-checkout-btn {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 0 15px;
      background: white;
      z-index: 100;
      input.checkout-buttons {
        width: 100%;
      }
    }
    .top-viewcart-buttons {
      display: none;
    }
    .checkout__sidebar {
      margin-bottom: 0px;
    }
    .field-container,
    li#choose-address {
      select {
        height: 60px;
        margin-bottom: 0;
        border: 1px solid #c7c7c7;
      }
    }
    #delivery-instructions {
      h4 label {
        display: inline;
      }
    }
  }
  &.review {
    #top-viewcart-buttons {
      display: none;
    }
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel,
      .continue-buttons {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0 15px 15px 15px;
        background: white;
        z-index: 100;
        .checkout-buttons {
          width: 100%;
        }
      }
    }
    .checkout-header__subhead {
      display: none;
    }
    #order-summary-panel {
      padding: 1em;
      background-color: #f0f0f0;
    }
    .site-footer {
      display: none;
    }
    .trans_header {
      .change_link {
        float: right;
        margin-right: 15%;
        margin-top: 6px;
      }
    }
    .review-panel {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
    }
    #checkout-sidebar {
      margin-bottom: 0px;
      #offer_code {
        margin-top: 15px;
      }
    }
  }
  .site-footer {
    display: none;
  }
  #confirmation-page {
    padding-top: 12px;
  }
  .confirmation {
    .opc__footer {
      padding: 2em 0 1em 0;
    }
  }
  #offer-code-panel {
    .offer-code__input {
      input[type='text'].js-label-mode + label:before {
        background: #f0f0f0;
      }
    }
  }
  .opc__footer {
    padding: 2em 0 6em 0;
  }
  select {
    height: 60px;
    border: 1px solid #c7c7c7;
    border-width: 1px;
  }
  .samples-page {
    .samples-panel__heading {
      font-size: 18px;
      text-transform: none;
    }
  }
  .bundle-kit {
    .product__desc-info {
      min-height: 50px;
    }
    .kit-container {
      .product__desc-info {
        min-height: auto;
      }
    }
  }
}

#shipping {
  #footer {
    display: none;
  }
  .site-header__tools {
    .site-bag__link {
      display: none;
    }
    .site-my-mac__link {
      display: none;
    }
  }
  #create_account_form_container {
    .email_container {
      .label-class {
        display: block;
      }
    }
    #offer-code-panel {
      .checkout__panel-content {
        overflow: visible;
      }
    }
  }
  .form_element {
    .overlay-addclass-checkout-additional-info-overlay {
      margin-left: 5px;
      position: relative;
      bottom: 5px;
    }
    .select2-container.field,
    select.field {
      width: 90% !important;
    }
  }
  .delivery_instructions_container {
    span.required_mark {
      float: left;
    }
    label {
      display: block;
    }
  }
  #order-summary-panel {
    padding: 1em;
    background-color: #f0f0f0;
    .order-summary-panel {
      &__title {
        font-size: 24px;
      }
    }
  }
  #delivery-info-content-panel {
    display: block;
  }
  .offer-code-panel {
    #offer_code {
      margin-top: 15px;
    }
  }
}

#review {
  .site-header__tools {
    .site-bag__link {
      display: none;
    }
    .site-my-mac__link {
      display: none;
    }
  }
  .order-summary-panel {
    padding: 1em;
    background-color: #f0f0f0;
    &__title {
      font-size: 24px;
    }
  }
  .qty select {
    height: 60px;
    border: 1px solid #c7c7c7;
    border-width: 1px;
  }
  .checkout {
    .cart-item {
      &__total {
        display: block;
        width: 25%;
      }
      .product__desc {
        width: 50%;
      }
      .remove_link {
        position: unset;
      }
    }
    .viewcart-buttons-panel {
      display: block;
    }
  }
  .site-footer {
    display: none;
  }
}

#payment-method {
  #payment-container {
    p label {
      display: block;
    }
  }
}

.shipping_wrapper {
  .fade-in {
    background: url('/media/images/global/ajax-loader.gif') no-repeat center center #000000;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0.5;
  }
}
