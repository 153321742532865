.back-to-mac {
  .back-to-mac__step {
    display: none;
  }
  .back-to-mac__intro {
    margin-bottom: 0px;
  }
}

.store-locator {
  .form--search--advanced {
    .form--search {
      height: 190px;
    }
    .form--search--intl {
      .submit_container {
        width: auto !important;
        text-align: center;
      }
    }
  }
}

.product.prod_inv_status-3 {
  .product__footer {
    .product__inventory-status {
      .coming-soon {
        display: block;
      }
    }
  }
}

.collection-landing-brick__content.collection-landing-brick__read-more {
  height: 45px !important;
}

.site-container {
  .after-product {
    .product__reviews-spp {
      display: none;
    }
  }
}

body.section-privacy-policy {
  .site-container {
    .narrowed_body_copy {
      h2 {
        font-size: 48px;
      }
    }
  }
}

#colorbox {
  iframe.waitlist-iframe-wrapper {
    width: 95%;
    height: 350px;
  }
}

.tiny-waitlist-overlay {
  #waitlist {
    .field {
      .email_input {
        width: 75%;
        float: left;
      }
      .align-r {
        float: left;
        input.input-btn {
          height: 64px;
          margin-left: 5px;
        }
      }
    }
    .waitlist_header {
      font-size: 3rem !important;
    }
  }
  .waitlist_thankyou_heading {
    font-size: 3rem;
  }
}

.site-container {
  .collection-detail-formatter {
    .prod_inv_status-2 {
      .product__footer {
        a.notify_me {
          line-height: inherit;
        }
      }
    }
  }
}

.product--teaser.product--collection-dual.product--not-shoppable + .carousel-controls {
  background: none;
}

#cboxClose {
  top: 3px;
  right: 3px;
  width: 18px;
  font-size: 1em;
}

.tout-block-landscape {
  @include swap_direction(padding, 0 0);
  img {
    @include swap_direction(margin, 0 0);
  }
}

@include breakpoint($bp--xlarge-down) {
  .container-table {
    width: 105%;
  }
  .cta-table {
    width: 185px;
    height: 67px;
    font-size: 15px;
    line-height: 53px;
    display: inline-block;
    @include swap_direction(margin, 0 29px 0);
    transform: translate(0, -398px);
  }
}

@include breakpoint($bp--xxlarge-up) {
  .container-table {
    width: 727px;
    @include swap_direction(margin, 0 0 38px);
    float: right;
  }
  .cta-table {
    width: 207px;
    height: 66px;
    font-size: 15px;
    line-height: 53px;
    display: inline-block;
    @include swap_direction(margin, 0 0 0 24px);
  }
}

.product-foundation-vto {
  &__overlay-title {
    line-height: 1.5;
  }
}

#search-wrapper {
  .prod_inv_status-2 {
    .product {
      &__add-to-bag {
        display: none;
      }
      &__link-custom {
        display: none;
      }
    }
  }
  .product {
    &--teaser {
      &.product {
        &--shaded {
          .product {
            &__link-to-spp {
              padding-left: 70px;
              width: 87%;
            }
          }
        }
      }
      .product {
        &__add-to-bag {
          padding-left: 70px;
          width: 87%;
        }
      }
    }
    &__link-custom {
      float: left;
      margin-left: -1px;
    }
    &--shaded {
      &.prod_inv_status-2 {
        .product {
          &__link-custom {
            display: block;
          }
        }
      }
    }
  }
}

.mpp-custom-override-layout {
  .product--teaser {
    background: $color-cta-grey--light;
    .product-brief {
      &__container {
        border: unset;
      }
    }
  }
}
