.favorites-page {
  .favorites-back {
    display: none;
  }
}

#registration_short,
#profile_preferences {
  .title_container {
    label.label {
      display: block;
      padding: 5px 0;
    }
  }
  .birthday-fields {
    display: block;
    padding-bottom: 5px;
  }
  .gender_label {
    display: block;
    padding-bottom: 5px;
  }
  .picker-radio {
    margin-top: 0;
  }
  .ship_type_container {
    span.required_mark {
      display: none;
    }
  }
}

.site-container {
  .account-page {
    .orders-list {
      .orders-list__table {
        td {
          a.order-detail-item-link {
            float: left;
          }
        }
      }
    }
  }
  .product__footer {
    a.notify_me {
      padding: 20px 0;
      width: 100%;
      margin: 0 !important;
      position: static;
    }
    .product-item__unavailable-message {
      position: relative;
      bottom: 85px;
      margin: 0;
    }
  }
}

.account-order-history {
  .past-purchases {
    .past-purchases__item {
      .product--teaser {
        .product__detail {
          width: 25.66667%;
        }
        .product__status {
          width: 20.66667%;
          margin-left: 30.77778%;
        }
        .product__qty {
          margin-left: 48.44444%;
        }
        .product__price {
          margin-left: 65.22222%;
        }
      }
      .product-header {
        .status {
          margin-left: 30.77778%;
        }
        .quantity {
          margin-left: 46.44444%;
        }
        .price {
          margin-right: 15px;
        }
      }
    }
  }
}

.order-details {
  .order-details-page {
    padding: 0 15px;
  }
  .order_status_table_container {
    padding-bottom: 15px;
    .order_table {
      width: 100%;
      float: none !important;
    }
  }
}

.fade-in {
  background: url('/media/export/images/global/ajax-loader-skybox.gif') no-repeat center;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  padding-top: 18px;
}
