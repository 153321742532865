/* Gnav styling */
.site-my-mac {
  .social-login {
    &__email-opt-in,
    &__terms {
      font-size: 17px;
      padding: 0;
    }
  }
  .gnav {
    .social-login {
      &__divider {
        .inner {
          padding: 7px 3px;
        }
      }
    }
  }
}
/* SignIn page styling */
.site-container {
  .sign-in-page {
    @include breakpoint($bp--medium-up) {
      .social-login-section {
        margin-#{$ldirection}: 50px;
        @include breakpoint($bp--xxlarge-up) {
          margin-#{$ldirection}: 0;
        }
      }
    }
    .social-login-registration {
      margin-top: 0;
      @include breakpoint($bp--medium-up) {
        margin-top: 428px;
      }
    }
    .social-login-section {
      .social-login {
        &__divider {
          .inner {
            padding: 7px 2px;
            @include breakpoint($bp--large-up) {
              #{$ldirection}: 96.1%;
              padding: 7px 3px;
              border-radius: 25px;
            }
          }
        }
        &__terms,
        &__email-opt-in {
          display: block;
          @include breakpoint($bp--xxlarge-up) {
            padding-#{$ldirection}: 51px;
          }
        }
      }
    }
    .divider {
      .social-login__divider {
        position: static;
        &:before {
          #{$ldirection}: 104%;
        }
        .inner {
          #{$ldirection}: 99.7%;
          top: 30%;
          @include breakpoint($bp--xxlarge-up) {
            #{$ldirection}: 100.7%;
          }
        }
      }
    }
    @include breakpoint($bp--medium-up) {
      .sign-in-container {
        width: 53%;
        @include breakpoint($bp--xxlarge-up) {
          width: 50%;
        }
      }
    }
    .field-container {
      .ship_type_container {
        .form-item {
          padding-#{$rdirection}: 1em;
          width: auto;
        }
      }
    }
    .social-login {
      &__terms {
        text-align: justify;
        padding: 0;
        margin-top: 0;
      }
    }
  }
}
/* Checkout page styling */
.checkout {
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-login {
        &__divider {
          .inner {
            padding: 7px 2px;
          }
        }
        &__terms {
          text-align: justify;
          padding: 0;
        }
      }
    }
  }
}
/* Confirmation Page */
.checkout-confirmation-page {
  .social-login {
    padding-#{$ldirection}: 20px;
    @include breakpoint($bp--medium-up) {
      padding-#{$ldirection}: 0;
    }
  }
}
