.postal_code_container {
  input {
    padding: 5px 35px;
  }
  label {
    margin-#{$ldirection}: 20px;
  }
  .icon {
    &--search {
      position: relative;
      #{$ldirection}: 0.5rem;
      top: -4.5rem;
    }
    &--arrow--down {
      position: relative;
      top: -4.2rem;
      float: $rdirection;
      #{$rdirection}: 10px;
    }
  }
}
