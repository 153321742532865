.appt-book {
  .appointment-nav {
    .submenu__content {
      .submenu__nav {
        .appointment-nav__link {
          font-size: 20px;
        }
      }
    }
  }
  .appt-book-page-header-content {
    .appt-book-page-header-content__dots {
      li {
        font-size: 20px;
        line-height: 25px;
        .appt-book-page-header-content__separator {
          height: 25px;
        }
      }
    }
    .appt-book-page-header-content__subhead {
      p {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
  .appt-book-location-selector {
    font-size: 20px;
    .form-container {
      .location-select {
        font-size: 18px;
        option {
          font-size: 18px;
        }
      }
    }
  }
  .service-select {
    .book-now-bar,
    .book-now-bar.bottom {
      .start-over {
        font-size: 20px;
        line-height: 25px;
      }
      .button {
        font-size: 25px;
        .book-now-bar__details {
          .price,
          .separator,
          .minutes {
            font-size: 25px;
          }
        }
      }
    }
    .services-container {
      .service {
        .service_tile_wrapper {
          .service-details {
            .service-type {
              font-size: 18px;
              line-height: 20px;
            }
            .service-title {
              line-height: 38px;
              font-size: 35px;
            }
            .service-selection-content {
              font-size: 18px;
              line-height: 20px;
            }
            .service-description {
              p {
                font-size: 18px;
                line-height: 20px;
              }
            }
          }
        }
        .selection-bar {
          .selection-data {
            font-size: 22px;
            bottom: 0px;
          }
          .selection-selector {
            font-size: 20px;
            line-height: 25px;
          }
        }
      }
    }
  }
  .appointment-select {
    .booking-step.step1 {
      .service-type,
      .service-head,
      .selection-time,
      .selection-price {
        font-size: 18px;
        line-height: 20px;
      }
      .service-head {
        line-height: 15px;
      }
    }
    .booking-step.step2 {
      .step-header {
        font-size: 25px;
        line-height: 25px;
      }
      .selectbox {
        font-size: 18px;
        option {
          font-size: 18px;
        }
      }
    }
    .appointments-container {
      .appointment-select {
        .calendar-head {
          .cal-head-content {
            .default-cal-copy,
            .cal-copy {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .confirm {
    .confirm-container {
      .sign-in-container {
        .sign-in-link-container {
          a,
          h4 {
            font-size: 18px;
            padding-left: 0px;
          }
        }
      }
      .book-appt-container {
        .book-appt-header {
          span {
            font-size: 18px;
          }
        }
        .registration__privacy_policy-container {
          .registration__privacy-list-text.label {
            font-size: 18px;
          }
        }
        p.appt-book-register-password-text {
          font-size: 18px;
        }
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .date-time {
        h4 {
          font-size: 20px;
          margin: 10px 0;
        }
        .appt-date-cal {
          background-position: 4.5em 0.25em;
        }
        .appt-date {
          span {
            font-size: 20px;
          }
        }
      }
      .your-lessons {
        span {
          font-size: 20px;
        }
      }
      .location {
        span {
          font-size: 20px;
        }
      }
    }
  }
  .my-appointments {
    .appointment-container {
      .appointment-details {
        .date-time {
          h4 {
            font-size: 20px;
            margin: 10px 0;
          }
          .appt-date-cal {
            background-position: 4.5em 0.25em;
          }
          .appt-date {
            span {
              font-size: 20px;
            }
          }
        }
        .your-lessons {
          span {
            font-size: 20px;
          }
        }
        .location {
          span {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.appt-book-overlay.appt-book-datepicker-overlay {
  .date-picker {
    .pikaday-container {
      .pika-single {
        .pika-lendar {
          .pika-title {
            .pika-label {
              font-size: 16px;
            }
          }
          table.pika-table {
            th {
              font-size: 16px;
            }
            td {
              .pika-button {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .date-picker-container {
    table.day {
      td {
        font-size: 18px;
      }
    }
  }
}
