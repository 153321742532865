#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-size: 17px !important;
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFlat {
      #onetrust-policy-text {
        font-size: 1.2em;
      }
      #onetrust-button-group {
        font-size: 1.3em;
      }
    }
  }
}
