.opc__footer {
  @include swap_direction(padding, 1em 0 6em 0);
  text-align: center;
}

.mobile-breadcrumb {
  .checkout-progress-bar__list-item {
    width: 25%;
  }
}

.checkout {
  &__index-content {
    .checkout__sidebar {
      display: block;
    }
  }
  &__sidebar {
    padding-bottom: 0;
  }
  &__content {
    .checkout__panel-title {
      display: block;
    }
  }
  .cart-item {
    &__qty {
      @include swap_direction(margin, 3% 0 0 50%);
    }
    &__total {
      display: inline;
      width: 35%;
    }
    &__product-name {
      padding-right: 35px;
    }
  }
}

.checkout.viewcart {
  .checkout__sidebar {
    padding-bottom: 0;
  }
}

.cart-item__total {
  display: block;
}

#confirmation-page {
  .checkout__sidebar {
    margin: 0;
  }
}

.adpl {
  .form-item {
    margin-bottom: 15px;
  }
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='text'] {
    &.error {
      border-color: $color--red;
    }
  }
}
