/*  styles for MAC select loyalty Program Overlay **/

@mixin a-color() {
  border-bottom: none;
  color: $color--white;
}

.sticky-footer-overlay,
.sticky-footer {
  width: 100%;
  visibility: visible;
  &.hide {
    visibility: hidden;
  }
  background: $color--black--opacity;
  color: $color--white;
  padding: 25px;
  position: absolute;
  z-index: 2;
  text-transform: none;
  &-slider {
    position: absolute;
    right: 15px;
    top: 8px;
    a {
      @include a-color();
      font-size: 25px;
    }
  }
  &-cols {
    @include breakpoint($bp--large-up) {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
    }
  }
  &-image {
    display: none;
    @include breakpoint($bp--large-up) {
      display: inline-block;
      width: 34%;
      img {
        max-width: 150px;
      }
    }
  }
  &-summary {
    h3 {
      font-size: 20px;
    }
    &__header {
      p {
        margin-bottom: 0;
      }
    }
    &__header,
    &__content {
      display: inline-block;
      text-align: left;
    }
    @include breakpoint($bp--large-up) {
      width: 30%;
      border-left: solid 1px;
      border-right: solid 1px;
      padding: 0 15px;
      h3 {
        font-size: 28px;
        margin-top: 0.3em;
        margin-bottom: 0.1em;
      }
      &__header {
        p {
          line-height: 1;
        }
      }
    }
  }
  &-join {
    @include breakpoint($bp--large-up) {
      width: 34%;
    }
    &__cta {
      font-size: 12px;
      a {
        color: $color--white;
      }
      @include breakpoint($bp--large-up) {
        font-family: $ano-bold_regular-font;
        background: $color--white;
        font-size: 17px;
        letter-spacing: 0;
        line-height: 50px;
        padding-left: 7px;
        margin-bottom: 4%;
        display: inline-block;
        padding: 0 20px;
        a {
          border-bottom: none;
        }
      }
      @include breakpoint($bp--largest-up) {
        font-size: 23px;
      }
    }
    &__signin {
      display: none;
      @include breakpoint($bp--large-up) {
        display: block;
        p {
          letter-spacing: 0.05em;
        }
        a {
          @include a-color();
        }
      }
    }
  }
}

@mixin col-mobile() {
  @include breakpoint($bp--large-up) {
    width: auto;
  }
}

.sticky-footer-links-formatter {
  background: $color--light--black;
  overflow: hidden;
  padding: 10px 0;
  font-size: 17px;
  position: relative;
  z-index: 3;
  .disable-pc {
    display: block;
    @include breakpoint($bp--large-up) {
      display: none;
    }
  }
  .disable-mobile {
    display: none;
    @include breakpoint($bp--large-up) {
      display: block;
    }
  }
  .col-md-2 {
    @include col-mobile();
    width: 50%;
  }
  .col-md-3 {
    @include col-mobile();
    width: 33.33%;
  }
  .col-md-4 {
    @include col-mobile();
    width: 25%;
  }
  .col-md-5 {
    @include col-mobile();
    width: 20%;
  }
  li {
    font-family: $ano-bold_regular-font;
    text-align: center;
    float: left;
    padding: 0 10px;
    border-left: 2px solid $white;
    @include breakpoint($bp--large-up) {
      font-weight: normal;
      letter-spacing: 0.2px;
      font-size: 17px;
      padding: 0 20px;
    }
    &.sticky-footer-links__right {
      @include breakpoint($bp--large-up) {
        float: right;
        border-right: solid 2px $color--white;
        border-left: none;
      }
      &.no-bar {
        border: none;
      }
    }
    &.no-bar {
      border: none;
    }
  }
  a {
    @include a-color();
    line-height: 25px;
    &:hover {
      @include breakpoint($bp--large-up) {
        text-decoration: underline;
      }
    }
  }
  // increase specificity for sticky footer links
  &__links {
    li {
      a { 
        color: $color--white;
        &:active,
        &:visited,
        &:focus {
          color: $color--white;
        }
        &:hover {
          border-bottom-color: $color--link-grey;
          color: $color--link-grey;
        }
      }
    }
  }
}
