$ano-bold_regular-font: 'ano-bold_regular', Arial, Sans-Serif !default;
$times-font: 'Times New Roman', Times, serif !default;
$roboto-condensed-bold-font: 'roboto-condensed-bold', Arial, Sans-Serif !default;
$roboto-condensed-regular-font: 'roboto-condensed-regular', Arial, Sans-Serif !default;
$roboto-mono_regular-font: 'roboto-mono_regular', Arial, Sans-Serif !default;
$roboto-mono_bold-font: 'roboto-mono_bold', Arial, Sans-Serif !default;
$tstar-font: 'tstar_mono_roundregular', Arial, Sans-Serif !default;
$tstar-bold-font: "tstar_mono_roundbold", Arial, Sans-Serif !default;

//Font-size update for thai texts
//font-size:12px changed to 17px
//font-size:14px changed to 19px
//font-size:15px changed to 20px
//font-size:18px changed to 22px
//font-size:10px changed to 15px

$fontsize_12: 12px !default;
$fontsize_15: 15px !default;
$fontsize_17: 17px !default;
$fontsize_19: 19px !default;
$fontsize_20: 20px !default;
$fontsize_22: 22px !default;

$roboto_dbhelvethaica: 'roboto-mono_regular', Arial, Sans-Serif !default;
$dbhelvethaica_trade: $ano-bold_regular-font, Helvetica, Arial, Sans-Serif !default;
$dbhelvethaica_times: 'Times New Roman',  Times, serif !default;
$dbhelvethaica_tstar: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif !default;

input, body, textarea {
  &:not(.header-search__field) {
    font-size: $fontsize_17 !important;
  }
}
fieldset {
  .field-container {
    font-size: $fontsize_17 !important;
  }
}
h6.sidebar-title {
  font-size: $fontsize_22 !important;
  a {
    font-size: $fontsize_17 !important;
  }
}
.btn, input[type="submit"] {
  font-size: $fontsize_20 !important;
}
.product__size, .product-size, .cart-item__size, .shade, .cart-item__qty, .shade-picker-dropdown, .product__mini-detail--size {
  font-size: $fontsize_12 !important;
  font-family: $roboto_dbhelvethaica !important;
}

//privacy-policy page and education page font
body.section-privacy-policy , body.section-counterfeit-education, .section-viva-glam-mac-aids-fund, .section-our-story, .section-animaltesting {
  .narrowed_body_copy {
    h5 {
      font-family: $dbhelvethaica_trade !important;
    }
  }
  strong , b {
    font-size: $fontsize_17 !important;
  }
}
body.section-privacy-policy {
  .narrowed_body_copy {
    h2 {
      font-family: $dbhelvethaica_trade !important;
      font-size: 60px;
    }
  }
}

// home page
.site-header {
  li.site-navigation__item.is-dropdown {
    .site-navigation__dropdown {
      .site-navigation__submenu-col {
        a {
          font-size: 17px !important;
        }
      }
    }
  }
}
.site-my-mac__contents-signin {
  .registration, .password-request {
    font-size: $fontsize_17 !important;
  }
}
.block-image-hover__content, .site-bag__contents-message, .site-bag__contents-edit, .site-my-mac__contents-account, .site-my-mac__contents-footer-link{
  font-size: $fontsize_17 !important;
}
.site-my-mac__link {
  font-size: $fontsize_15 !important;
  padding-top: $fontsize_12 !important;
}

//MPP SPP page
.product__description-short, .product__description-group-body, .product__description-trigger-title {
  font-size: $fontsize_17 !important;
}

// Footer

.site-footer {
  .grid--item {
    h6 {
     font-family: $dbhelvethaica_trade !important;
     font-size: $fontsize_22;
    }
    ul.menu {
      font-size: $fontsize_17;
    }
  }
  .country a {
    font-size: $fontsize_17;
  }
}

//Mobile footer
.footer-menu--mobile {
  ul.menu {
    li {
      font-size: $fontsize_17 !important;
      .menu-container {
        ul.menu {
          li {
            font-size: $fontsize_17 !important;
          }
        }
      }
    }
  }
}

//Culture
.culture-landing__teaser-description, .culture-landing__tracks__meta-info, .culture-feature__carousel-description, .culture-detail__article-content__body-above, .culture-detail__article-content__body-between {
  font-family: $dbhelvethaica_times !important;
}
.culture-landing__tracks__header-edition {
  font-family: $times-font !important;
}
.culture-detail__article-content__subtitle {
  font-family: $dbhelvethaica_tstar !important;
}
.culture-detail__header-featured {
  font-family: $roboto_dbhelvethaica !important;
}
.culture-detail__article-content__meta-date {
  font-size: $fontsize_12;
}

//Account pages
#email_sms_signup {
  .intro {
    font-size: $fontsize_17;
  }
}

//Checkout pages
.customer-service-header, #shipping h3, .checkout-navigation__menu .site-navigation__link, .offer-code__submit input[type="submit"], .order-summary__tax-label .tax_rate, .confirmation-panel__confirm-text {
  font-family: $dbhelvethaica_trade !important;
}

//Content Pages
h3.artist-tile__header, .director {
  font-family: $roboto_dbhelvethaica !important;
  font-size: $fontsize_12;
}
.multi-use-tout__body {
  font-size: $fontsize_17 !important;
}
.artist--interview {
  h2, h3 {
    font-size: $fontsize_22 !important;
    font-family: $dbhelvethaica_trade !important;
  }
}
.section-terms-conditions, .section-our-story {
  h4, h5 {
    font-family: $dbhelvethaica_trade !important;
  }
}
.store-locator-landing__title, .store-locator__submit, .results_side-title {
  font-family: $dbhelvethaica_trade !important;
}
.section-makeup-services h6 {
  font-family: $dbhelvethaica_trade !important;
  font-size: $fontsize_22 !important;
}
.contact-us-page {
  div.title, .customer-service-title {
    font-family: $dbhelvethaica_trade !important;
    font-size: $fontsize_20 !important;
  }
  .question {
    font-family: $dbhelvethaica_trade !important;
    font-size: $fontsize_19 !important;
  }
}
.customer-service__navigation {
  .customer-service__menu {
    h6 {
      font-family: $dbhelvethaica_trade !important;
      font-size: $fontsize_19 !important;
    }
  }
}
.section-customer-service-faq {
  .mac-faq__q {
    font-family: $dbhelvethaica_trade !important;
    font-size: $fontsize_22 !important;
  }
}
.section-mac-pro {
  .responsive-container {
    h2 {
      font-family: $dbhelvethaica_trade !important;
    }
  }
}
.section-animaltesting {
  h4 {
    font-family: $dbhelvethaica_trade !important;
  }
}
.header-search__result__description {
  font-size:: $fontsize_15 !important;
}
.section-employment {
  h5 {
    font-family: $dbhelvethaica_trade !important;
  }
}
b, strong {
  font-size: $fontsize_17 !important;
}
.media-block__cta, .block-image-overlay__link {
  a {
    font-family: $roboto_dbhelvethaica !important;
    font-size: $fontsize_12 !important;
  }
}
.block-image-overlay__content p a {
  font-family: $roboto_dbhelvethaica !important;
  font-size: $fontsize_12 !important;
}
.block-image-hover__content {
  .block-image-hover__link {
    font-family: $roboto_dbhelvethaica !important;
    font-size: $fontsize_12 !important;
  }
}
.media-block__caption {
  .media-block__subheadline {
    font-family: $roboto_dbhelvethaica !important;
    font-size: $fontsize_12 !important;
  }
}
#zero-results .font-tstar-bold {
  font-size: $fontsize_17 !important;
  padding: 1.2em !important;
}
